import React from "react"
import infoEmail from "../../../images/info-email.png"
import Button from "../../../components/button"
import './styles.css';
const AdvancedFundamentalsOfAiInEducation = () => {
  const styles = {
    heading: {
      marginBottom: "10px",
      marginTop: "30px",
    },
    button: {
      cursor: "pointer",
      color: "white",
      textAlign: "center",
      fontWeight: 700,
      backgroundColor: "#092c4c",
      fontSize: "18px",
      padding: "15px 35px",
      borderRadius: "30px",
      display: "block",
      marginTop: "20px",
    },
    divsmall: {
      marginBottom: "15px",
    },
    div: {
      marginBottom: "35px",
    },
  }

  return (
    <div>
       <h6 style={styles.heading}>Aim</h6>
      <div style={styles.div}>
      The purpose of this micro-credential is to equip educators with advanced knowledge and practical skills in the application of AI for education. It delves into AI-driven assessment methods, collaborative projects, professional development, curriculum integration, and the future of AI in educational contexts. Educators will learn to leverage AI tools to enhance teaching practices, improve learning outcomes for the educator's students, and prepare fellow educators for emerging AI trends in education.
      </div>
      <div style={styles.div}>Educators will integrate their learning and project work into their weekly work schedules in their respective schools and educational organisations. This integration ensures that the theoretical and practical aspects of the micro-credential are directly applied to real-world educational settings, enhancing the relevance and impact of their learning experiences.
        <ol>
          <li>Applying AI-Driven Assessment and Feedback Mechanisms: Learners will design and
implement AI-driven assessment tools and feedback systems, evaluating their accuracy
and effectiveness in educational settings. They will analyse assessment data to inform
instructional strategies and improve learning outcomes.</li>
          <li>Facilitating Collaborative Learning and Professional Development with AI: Learners will utilise AI tools to create and manage collaborative learning projects, enhancing peer-to-peer interaction and co-creation of educational content. They will design and implement AI-enhanced professional development programmes, personalising learning journeys and promoting continuous improvement in teaching practices.</li>
          <li>Integrating AI Technologies into Educational Curricula and Planning for Future Trends: Learners will develop and implement strategic plans for integrating AI technologies into educational curricula, identifying opportunities for innovation and improvement. They will analyse emerging AI trends and formulate forward-thinking strategies to prepare educational institutions for future technological advancements, ensuring ethical standards and promoting equity.</li>
        </ol>
      </div>
      <h6 style={styles.heading}>Outcome</h6>
      <div style={styles.div}>On successful completion of this micro-credential, learners/akonga will be able to:
      <ol>
      <li>Apply AI-driven assessment and feedback mechanisms in an education setting.</li>
      <li>Facilitate collaborative learning and professional development for educators with AI.</li>
      <li>Integrate AI technologies into educational curricula and anticipate future trends in AI
      for education.</li>
      </ol>  
      </div>
      <h6 style={styles.heading}>Entry</h6>
      <div style={styles.div}>
        <p>Basic proficiency in educational technology and teaching experience is
recommended.</p>
<p>Prior exposure to AI concepts or tools is advantageous but not mandatory.</p>
<p>Proficiency in English (IELTS 6.0 or equivalent) for non-native speakers.</p>
      </div>
      <h6 style={styles.heading}>Standards</h6>
      <div style={styles.div}>
      There are no standards associated with this programme or micro-credential.
      </div>
      <h6 style={styles.heading}>Awards</h6>
      <div style={styles.div}>
      Qualification(s) and/or micro-credential(s) completed as part of this programme:
      <table className="table-container">
      <thead>
        <tr>
          <th>Reference</th>
          <th>Title</th>
          <th>Level</th>
          <th>Credits</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>5111 - 1</td>
          <td>Advanced Applications of AI in Education (Micro-credential) - Summit 6 10 Institute Limited</td>
          <td>6</td>
          <td>10</td>
        </tr>
      </tbody>
    </table>
      </div>
      <h6 style={styles.heading}>Delivery and Assessment</h6>
      <strong style={styles.heading}>Provider</strong>
      <div style={styles.divsmall}>
      Summit Institute Limited (6659/1)
      </div>
      <strong style={styles.heading}>Delivery mode(s)</strong>
      <div style={styles.divsmall}>
      Face-to-Face & Distance
      </div>
      <strong style={styles.heading}>Delivery method(s)</strong>
      <div style={styles.divsmall}>
     <p style={{marginBottom: '15px'}}>Mode: Blended & Distance learning combining online instruction distance
     delivery and face-to-face workshops.</p>
     <p style={{marginBottom: '15px'}}>Methods:</p>
     <p style={{marginBottom: '15px'}}>Lectures: Providing theoretical knowledge and foundational concepts of AI in education</p>
     <p style={{marginBottom: '15px'}}>Tutorials: Offering guided, interactive sessions to deepen understanding and facilitate discussions on key topics</p>
     <p style={{marginBottom: '15px'}}>Hands-on Practice with AI Tools: Allowing learners to engage directly with AI technologies, enhancing practical skills through real-world applications.</p>
     <p style={{marginBottom: '15px'}}>Group Discussions: Encouraging collaborative learning and the exchange of
     ideas among peers, fostering a deeper understanding of the subject matter.</p>
     <p style={{marginBottom: '15px'}}>By employing a Blended & Distance learning approach, this micro-credential ensures that learners benefit from a comprehensive educational experience that integrates theoretical knowledge with practical application, supported by collaborative learning opportunities.</p>
     </div>
     <strong style={styles.heading}>Assessment Method(s)</strong>
     <div style={styles.divsmall}>
     <p style={{marginBottom: '15px'}}>Assessment Materials - Tools and Activities:</p>
     <ol>
      <li>AI and Assessment: AI Assessment Creation and Evaluation: Create and grade assessments using an AI tool, provide feedback, and evaluate the accuracy, fairness, and biases in a comprehensive report.</li>
      <li>Collaborative Projects with AI: Collaborative AI Project Analysis and Implementation: Implement a collaborative project using an AI tool, document the process, and evaluate its effectiveness in a detailed case study with a practical demonstration.</li>
      <li>Professional Development with AI: AI-Driven Professional Development Implementation: Generate personalised training recommendations and create a simulated training scenario using an AI tool. Document and evaluate the process in a comprehensive report.</li>
      <li>Integrating AI into the Curriculum: AI Curriculum Integration Plan: Develop and implement a strategic plan for integrating AI tools into a curriculum. Document the process and evaluate its impact on student learning outcomes.</li>
      <li>Future of AI in Education: Future AI in Education Plan: Write a report predicting the future of AI in education, discuss lesson plans and curriculum development ideas incorporating emerging AI technologies, and evaluate readiness for future integration.<br></br>General Assessment Methodology: Competency-Based Assessments: Ensure all assessments are competency-based to meet required standards. Feedback and Reassessment: Provide feedback and opportunities for reassessment.<br></br>Moderation: Implement internal and external moderation processes to ensure fairness, validity, and reliability of assessment decisions.</li>
     </ol>
     </div>
     <h6 style={styles.heading}>Length/duration</h6>
     <div style={styles.divsmall}>
      <strong style={styles.heading}>Total (weeks)</strong>
      <p>10 Including holiday weeks </p></div>
      <div style={styles.divsmall}>
      <strong style={styles.heading}>Training/Teaching (weeks)</strong>
      <p>10 Excluding holiday weeks </p></div>
      <div style={styles.divsmall}>
      <strong style={styles.heading}>Total learning hours (per week)</strong>
      <p>10.00</p>
     </div>
      <div className="contact-content" style={{width:"100%",background:"#083051",paddingTop:"30px",paddingBottom:"30px"}}>
              <h3 className="contact-heading">We'd love to hear from you</h3>
              <span style={{ marginBottom: "10px" }}>
                You can contact us at
              </span>
              <img
                src={infoEmail}
                style={{ marginBottom: "20px"}}
                alt="email address" className="responsive-img"
              />
              <span style={{ marginBottom: "20px" }}>
                and one of our team will be in touch
              </span>
              <a href="mailto:contact@summitinstitute.ac.nz?subject=Mail from Summit Institute">
                <Button colorSchema="sec">Email us</Button>
              </a>
            </div>
    </div>
  )
}

export default AdvancedFundamentalsOfAiInEducation
